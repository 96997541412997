"use client"
import TextFieldComponent from "@/Inputs/TextFieldComponent";
import localStorageCall from "@/services/Methods/localstorage.hook";
import { Fragment, useContext, useState } from "react";
import { getCustomerRefferal } from "@/services/Redux/Reducer/CustomerSlice";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { Close, Search } from "@mui/icons-material";
import ButtonComponent from "@/Inputs/ButtonComponent";
import { ContextData, MiniLoader, _scrollToUp, _setTimeOut, cn, storeAssigned } from "@/services/Methods/normalMethods";
import { RegionDropdown } from "react-country-region-selector";
import { createSelector } from 'reselect';
import { useRouter } from "next/navigation";
import { ceil } from "lodash";
import ReactpaginateComp from "@/comman/ReactPaginateComp";
import { RefferalContent } from "@/comman/RefferalCheckPopup";
import withRouteHOC from "@/HOC/withRouterHOC";

const ReferralPopup = (props) => {
    const { section, openPopup, setOpenPopup, searcheddata, setSearcheddata, offset, setOffSet, router } = props;
    const dispatch = useDispatch();

    const mamoSelector = createSelector(
        (state) => state?.CustomerSlice?.isLoading,
        (data) => ({ isLoading: data })
    )
    const { isLoading } = useSelector((state) => mamoSelector(state))

    const InputField = (name, label, value, handleChange, type, disable) => {
        return (
            <div className='md-3-field xl:mb-[34px] mb-[24px]'>
                <TextFieldComponent
                    name={name}
                    type={type ? type : 'text'}
                    label={label}
                    value={value.trim()}
                    errorMessage={" "}
                    // disabled={disable}
                    divClass={'w-full !mb-0'}
                    onChange={handleChange}
                />
            </div>
        )
    };

    const [referraldata, setReferraldata] = useState({ data: [], totalRecords: 0 });
    const [errorDisplay, setErrorDisplay] = useState('');

    const selectedItem = (data) => {
        const refferalData = { "id": data?.customerId, "name": data?.fullName || data?.publicProfile?.fullName, "webalies": data?.webAlias, userDetail: data };

        if (props?.urlRefferal?.webalies) {
            // urlRefferal, setUrlRefferal, _handleYesClick
            props?._handleYesClick('yes', refferalData)

        } else {
            console.log(referraldata)
            console.log("data", data)
            console.log("inside_the_esectonfsjfjf");
            localStorageCall().setItem("refferal_link", JSON.stringify(refferalData));
            setOpenPopup(false)
            setReferraldata([]);
            router.push(`/${storeAssigned()}/${section}`)
        }

    }

    const [formData, setFormData] = useState({ firstName: '', lastName: '', city: '', zipcode: '', state: '', webAlias: '' });
    const [selectedReferral, setSelectedReferral] = useState(null);

    const handleformChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleFormSubmit = (e, currentPage = offset - 1) => {
        if (e)
            e.preventDefault();
        console.log(formData, offset, 'formDataformDataformData')
        // const VALUES = _.compact(_.values(formData));
        const VALUES = _.compact(_.values(formData)).map(value => value.trim());

        if (VALUES?.length > 0) {
            dispatch(getCustomerRefferal((data, totalRecords) => {
                if (data?.length > 0) {
                    setReferraldata({ data: data, totalRecords })
                    setSearcheddata('list')

                } else {
                    setErrorDisplay('No record found with these detail');
                    _setTimeOut(() => setErrorDisplay(''), 3000);
                }
            }, formData, 'normal', currentPage));
            // setFormData(VALUES);
            // setFormData({ firstName: '', lastName: '', city: '', zipcode: '', state: '' })
        } else {
            setErrorDisplay('Please fill any field for search');
            _setTimeOut(() => setErrorDisplay(''), 3000);
        }

    }

    function _onPaginationChange(e) {
        const selectedPage = e.selected;
        const offsetpage = selectedPage + 1;
        setOffSet(offsetpage)

        handleFormSubmit(null, offsetpage - 1);

        // dispatch(getAllProductApiPagination(offsetpage - 1))
        _scrollToUp();
        // paginateData(Logintoken, offsetpage)
    }

    const route = useRouter()


    const closePopup = () => {
        setOpenPopup(false)
        // if (section === 'checkout') {
        //     router.push(`/${storeAssigned()}/${section}`)
        // }
        route.back()
    }
    const blacklist_state = { US: ['AS', 'FM', 'GU', 'MH', 'MP', 'PW', 'PR', 'AA', 'AE', 'AP'] };

    return (
        <Transition appear show={openPopup} as={Fragment}>
            <Dialog as="div" className="relative z-[1400]" onClose={() => console.log('close')}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-[rgba(0,0,0,0.8)]" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center text-center px-[15px]">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={cn("z-[1400] mx-0 w-full max-w-[800px] transform md:p-7 p-5 bg-white  text-left align-middle  transition-all rounded-md", { "max-w-[470px]": searcheddata === "confirmation" })}>
                                {!props?.urlRefferal?.webalies &&
                                    <div className="text-right">
                                        <Close
                                            className="mb-1 ml-auto cursor-pointer"
                                            onClick={() => {
                                                // setOpenPopup(false);
                                                // if (section === 'checkout') {
                                                //     router.push(`/${storeAssigned()}/${section}`)
                                                // }
                                                closePopup()
                                            }}
                                        />
                                    </div>
                                }
                                <div className="py-2 mb-[20px]">
                                    <h3 className="font-bold">Referral Search</h3>
                                </div>
                                {
                                    (searcheddata === "list") &&
                                    <div className="">
                                        <div className="h-[400px] overflow-y-scroll">
                                            <table className="table-auto w-full">
                                                <thead>
                                                    <tr>
                                                        {_.map(['ID', 'Name', 'Code', 'Action'], (row) => (
                                                            <th key={row} className="border border-[#ddd] p-[10px]">{row}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                {console.log(referraldata?.data, 'referraldata?.data')}
                                                <tbody>
                                                    {_.reject(referraldata?.data, { customerId: 100 })?.map((customer) => (
                                                        <tr className="p-5" key={customer?.customerId}>
                                                            <td className="border border-[#ddd] p-[10px]">{customer?.customerId}</td>
                                                            <td className="border border-[#ddd] p-[10px]">{customer?.publicProfile?.fullName}</td>
                                                            <td className="border border-[#ddd] p-[10px]">{customer?.webAlias || 'N/A'}</td>
                                                            <td className="border border-[#ddd] p-[10px]">
                                                                <ButtonComponent classes="!mb-0 p-[5px] max-w-[150px]" onClick={(e) => {
                                                                    setSearcheddata('confirmation');
                                                                    setSelectedReferral(customer);
                                                                }}>Select</ButtonComponent>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {
                                            (referraldata?.data?.length > 0) &&
                                            <ReactpaginateComp currentPage={offset} pageCount={ceil(referraldata?.totalRecords / 10)} handlePageClick={(e) => {
                                                _onPaginationChange(e)
                                            }} />
                                        }
                                        <div className="bg-white-500">
                                            <div className="w-full max-w-[200px] my-0 mx-auto relative  mt-[12px] ">
                                                <ButtonComponent classes="text-[18px] !mb-0" onClick={() => {
                                                    setSearcheddata('form');
                                                    setOffSet(1);
                                                }}>&lt; Back</ButtonComponent>
                                            </div>
                                        </div>

                                    </div>
                                }
                                {(searcheddata === "form") &&
                                    <form action="" onSubmit={handleFormSubmit}>
                                        <div className='row-form gap-[0_16px] flex flex-wrap'>
                                            <div className="sm:w-[calc(100%/2-8px)] w-full">
                                                {InputField('firstName', "First Name", formData.firstName, handleformChange)}
                                            </div>
                                            <div className="sm:w-[calc(100%/2-8px)] w-full">
                                                {InputField('lastName', "Last Name", formData.lastName, handleformChange)}
                                            </div>
                                            <div className="sm:w-[calc(100%/2-8px)] w-full">
                                                {InputField('city', "City", formData.city, handleformChange)}
                                            </div>
                                            <div className="sm:w-[calc(100%/2-8px)] w-full">
                                                {InputField('zipcode', "Zip Code", formData.zipcode, handleformChange)}
                                            </div>
                                            <div className="sm:w-[calc(100%/2-8px)] w-full xl:mb-[34px] mb-[24px]">
                                                <RegionDropdown
                                                    country={"US"}
                                                    blacklist={blacklist_state}
                                                    value={formData.state}
                                                    name="state"
                                                    valueType="short"
                                                    defaultOptionLabel={"State"}
                                                    countryValueType="short"
                                                    className="floating-input bg-[transparent] border-clr border-[1px] px-3.5 pt-4 pb-3 block w-full font-medium placeholder:text-black text-base rounded-[6px] h-[54px]"
                                                    onChange={(e) => { handleformChange({ target: { name: 'state', value: e } }) }}
                                                />
                                            </div>
                                            <div className="sm:w-[calc(100%/2-8px)] w-full">
                                                {InputField('webAlias', "Web-Alias", formData.webAlias, handleformChange)}
                                            </div>
                                        </div>
                                        <div className="w-full  max-w-[300px] my-4 mx-auto">
                                            <ButtonComponent classes="text-[18px] !mb-0" type="submit">{isLoading ? <MiniLoader /> : <><Search />Search</>}</ButtonComponent>
                                        </div>
                                        {errorDisplay && <div className="text-sm text-center text-red-500 mt-2">{errorDisplay}</div>}
                                    </form>
                                }
                                {searcheddata === "confirmation" &&

                                    <RefferalContent {...{
                                        urlRefferal: { userDetail: selectedReferral },
                                        yesFunction: () => selectedItem(selectedReferral),
                                        noFunction: () => setSearcheddata('list')
                                    }} />
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withRouteHOC(ReferralPopup);