"use client"
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonComponent from "@/Inputs/ButtonComponent";
import { _setTimeOut } from "@/services/Methods/normalMethods";

export const RefferalContent = ({ urlRefferal, yesFunction, noFunction }) => {

    return (

        <>
            <div className="text-center w-full max-w-[410px] m-[0px_auto_10px] gap-[8px] text-[20px] border-b-2 border-[#000]">
                Are you sure you want to use this referral?
            </div>
            <div className="py-2 text-center ">
                <h3 className="font-bold mb-3">Referral Code: {urlRefferal?.userDetail?.webAlias}</h3>
                <div className="w-full max-w-[380px] my-0 mx-auto">
                    <div className="flex items-center gap-[8px]">
                        <strong>Name:</strong>
                        <p>{urlRefferal?.userDetail?.publicProfile?.fullName || urlRefferal?.userDetail?.fullName}</p>
                    </div>
                    <div className="flex items-center gap-[8px]">
                        <strong>Email:</strong><p>{urlRefferal?.userDetail?.emailAddress}</p>
                    </div>
                    <div className="flex items-center gap-[8px]">
                        <strong>Address:</strong>
                        <p>{urlRefferal?.userDetail?.mailingAddress?.city}, {urlRefferal?.userDetail?.mailingAddress?.postalCode}, {urlRefferal?.userDetail?.mailingAddress?.regionProvState}, {urlRefferal?.userDetail?.mailingAddress?.countryCode}</p>
                    </div>

                    {/* <div><strong></strong>: <p></p>{urlRefferal?.userDetail?.webAlias}</div> */}

                </div>
            </div>

            <div className="w-full mt-5 flex justify-center gap-[20px]">
                <ButtonComponent classes="max-w-[110px] !mb-0" onClick={() => yesFunction()}>Yes</ButtonComponent>
                <ButtonComponent classes="max-w-[110px] !mb-0" onClick={() => noFunction()}>No</ButtonComponent>
            </div>
        </>
    )
}


const RefferalCheckPopup = (props) => {

    const { refferalCheckPopup, setRefferalCheckPopup, _handleYesClick, urlRefferal, setOpenPopup } = props;
    console.log(urlRefferal, 'urlRefferalurlRefferalurlRefferalurlRefferal')
    return (
        <>
            <Transition appear show={refferalCheckPopup} as={Fragment}>
                <Dialog as="div" className="relative z-[1400]" onClose={() => console.log('close')}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[rgba(0,0,0,0.8)]" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center text-center px-[15px]">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className=" relative z-[1400] mx-4 sm:mx-0 w-full max-w-[500px] transform p-7 bg-white  text-left align-middle  transition-all rounded-md">
                                    {/* <div className="flex flex-col gap-[30px]"> */}
                                    <RefferalContent {...{
                                        urlRefferal,
                                        yesFunction: () => _handleYesClick('yes', urlRefferal),
                                        noFunction: () => {
                                            setRefferalCheckPopup(false);
                                            setOpenPopup(true);
                                        }
                                    }} />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition >
        </>
    )
}

export default RefferalCheckPopup;